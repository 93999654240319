import dayjs from 'dayjs'
import React from 'react'
import { envs } from '../../../common/Campin'
import { BigFigure, Card, Check } from '../../component/BigFigure'
import { Block } from '../../component/Block'
import { useMetrics } from '../../hook/useMetrics'

const dateToString = (value?: string) => {
  if (!value) {
    return ''
  }
  return dayjs(value).format('DD/MM/YY [à] HH:mm')
}

export const CampinTemplateLivrer = (props: {
  campin: Record<envs, string>
}) => {
  const [getMetric, status, statusColor] = useMetrics()

  return (
    <Block title="Livrer" status={status} statusColor={statusColor}>
      {['test', 'demo', 'prod'].map((env) => {
        return (
          <React.Fragment key={env}>
            <h3>{env}</h3>
            <div className="Component_row">
              <Card
                href={getMetric(`gitlab.deploy.${env}.server.url` as any).value}
              >
                <Check
                  title="serveur"
                  extValue={getMetric(
                    `gitlab.deploy.${env}.server.status` as any
                  )}
                  small
                  legend={dateToString(
                    getMetric(`gitlab.deploy.${env}.server.date` as any).value
                  )}
                  okValue="success"
                />
              </Card>
              <Card
                href={getMetric(`gitlab.deploy.${env}.ios.url` as any).value}
              >
                <Check
                  title="app mobile ios"
                  extValue={getMetric(`gitlab.deploy.${env}.ios.status` as any)}
                  small
                  legend={dateToString(
                    getMetric(`gitlab.deploy.${env}.ios.date` as any).value
                  )}
                  okValue="success"
                />
              </Card>
              <Card
                href={
                  getMetric(`gitlab.deploy.${env}.android.url` as any).value
                }
              >
                <Check
                  title="app mobile android"
                  extValue={getMetric(
                    `gitlab.deploy.${env}.android.status` as any
                  )}
                  small
                  legend={dateToString(
                    getMetric(`gitlab.deploy.${env}.android.date` as any).value
                  )}
                  okValue="success"
                />
              </Card>
              <Card href={props.campin[env as envs]}>
                <BigFigure
                  title="version"
                  extValue={getMetric(`campin.${env as envs}.version`)}
                  small
                />
              </Card>
            </div>
          </React.Fragment>
        )
      })}
    </Block>
  )
}
