import dayjs from 'dayjs'
import React from 'react'
import { BigFigure, Card } from '../../component/BigFigure'
import { Block } from '../../component/Block'
import { useMetrics } from '../../hook/useMetrics'

const dateToString = (value?: string) => {
  if (!value) {
    return ''
  }
  return dayjs(value).format('DD/MM/YY')
}

export const CampinTemplatePlanifier = () => {
  const [getMetric, status, statusColor] = useMetrics()

  return (
    <Block title="Planifier" status={status} statusColor={statusColor}>
      <h3>Workflow</h3>
      <div className="Component_row">
        <Card href="https://anikop.atlassian.net/jira/software/c/projects/CAM/issues/?jql=status%20%3D%20%22%C3%80%20qualifier%22">
          <BigFigure
            title="A qualifier"
            extValue={getMetric('jira.a_qualifier.nb')}
            legend="tickets"
          />
        </Card>
        <Card href="https://anikop.atlassian.net/jira/software/c/projects/CAM/issues/?jql=status%20%3D%20%22A%20estimer%22">
          <BigFigure
            title="A estimer"
            extValue={getMetric('jira.a_estimer.nb')}
            legend="tickets"
          />
        </Card>
        <Card href="https://anikop.atlassian.net/jira/software/c/projects/CAM/issues/?jql=status%20%3D%20%22A%20faire%22">
          <BigFigure
            title="A faire"
            extValue={getMetric('jira.a_faire.nb')}
            legend="tickets"
            indicator={getMetric('jira.a_faire.story_points').value}
          />
        </Card>
      </div>
      <div className="Component_row">
        <Card href="https://anikop.atlassian.net/jira/software/c/projects/CAM/issues/?jql=status%20%3D%20%22A%20faire%22">
          <BigFigure
            title="Affinage"
            legend="de tickets estimés"
            renderValue={(v) => {
              return v + '%'
            }}
            extValue={getMetric('jira.a_faire.story_points_perc')}
          />
        </Card>
      </div>
      <h3>Sprint en cours</h3>
      <div className="Component_column">
        <Card href="https://anikop.atlassian.net/secure/RapidBoard.jspa?projectKey=CAM">
          <div className="Component_column" style={{ marginBottom: 20 }}>
            <BigFigure title={getMetric('jira.sprint.nom').value} />
            <div className="Component_row" style={{ marginTop: 20 }}>
              <BigFigure
                legend="Date de début"
                value={dateToString(getMetric('jira.sprint.date_debut').value)}
                small
              />
              <BigFigure
                legend="Date de fin"
                value={dateToString(getMetric('jira.sprint.date_fin').value)}
                small
              />
            </div>
          </div>
        </Card>
      </div>
      <div className="Component_row">
        <Card href="https://anikop.atlassian.net/secure/RapidBoard.jspa?projectKey=CAM">
          <BigFigure
            title="A faire"
            extValue={getMetric('jira.sprint.a_faire.nb')}
            legend="tickets"
            indicator={getMetric('jira.sprint.a_faire.story_points').value}
          />
        </Card>
        <Card href="https://anikop.atlassian.net/secure/RapidBoard.jspa?projectKey=CAM">
          <BigFigure
            title="En cours"
            extValue={getMetric('jira.sprint.en_cours.nb')}
            legend="tickets"
            indicator={getMetric('jira.sprint.en_cours.story_points').value}
          />
        </Card>
        <Card href="https://anikop.atlassian.net/secure/RapidBoard.jspa?projectKey=CAM">
          <BigFigure
            title="Terminés"
            extValue={getMetric('jira.sprint.termine.nb')}
            legend="tickets"
            indicator={getMetric('jira.sprint.termine.story_points').value}
          />
        </Card>
      </div>
      <h3>Bugs</h3>
      <div className="Component_row">
        <Card href="https://anikop.atlassian.net/jira/software/c/projects/CAM/issues/?jql=project%20%3D%20%22CAM%22%20AND%20type%20IN%20%28%22Bug%20BO%22%2C%22Bug%20charte%22%2C%22Bug%20mobile%22%29%20AND%20status%20%3D%20%22A%20faire%22%20ORDER%20BY%20created%20DESC">
          <BigFigure
            title="A corriger"
            extValue={getMetric('jira.bugs.a_corriger')}
            legend="bugs"
          />
        </Card>
        <Card href="https://anikop.atlassian.net/jira/software/c/projects/CAM/issues/?jql=project%20%3D%20%22CAM%22%20AND%20type%20IN%20%28%22Bug%20BO%22%2C%22Bug%20charte%22%2C%22Bug%20mobile%22%29%20AND%20status%20IN%20%28%22En%20cours%22%2C%22Fini%20et%20%C3%A0%20tester%20PO%22%2C%22Fini%20technique%22%2C%22Valid%C3%A9%20pour%20MEP%22%29%20ORDER%20BY%20created%20DESC">
          <BigFigure
            title="En cours"
            extValue={getMetric('jira.bugs.en_cours')}
            legend="bugs"
          />
        </Card>
      </div>
    </Block>
  )
}
