import React from 'react'
import { AllMetrics } from '../../common/Campin'
import { CampinTemplateConstruire } from './campin/construire'
import { CampinTemplateLivrer } from './campin/livrer'
import { CampinTemplateOperer } from './campin/operer'
import { CampinTemplatePlanifier } from './campin/planifier'
import { CampinTemplateTester } from './campin/tester'

export const CampinTemplate = (getMetric: (name: AllMetrics) => string) => {
  return (
    <div>
      <CampinTemplatePlanifier />
      <CampinTemplateConstruire />
      <CampinTemplateTester />
      <CampinTemplateLivrer campin={{
        test: 'https://test.campin.fr',
        demo: 'https://demo.campin.fr',
        prod: 'https://bo.campin.fr'
      }} />
      <CampinTemplateOperer env="test" boUrl="https://test.campin.fr" />
      <CampinTemplateOperer env="demo" boUrl="https://demo.campin.fr" />
      <CampinTemplateOperer env="prod" boUrl="https://bo.campin.fr" />
    </div>
  )
}
