import React from 'react'
import { ReactNode } from 'react'
import { PropsWithChildren } from 'react'
import { Warning } from '../icons/warning'

export const Check = (props: {
  title?: string
  value?: string
  extValue?: {
    value?: string
    alert?: 'Warning' | 'Error'
  }
  renderValue?: (v?: string) => ReactNode
  legend?: string | ReactNode
  indicator?: string
  small?: boolean
  alert?: any
  on?: string | ReactNode
  okValue: string
}) => {
  
  return (
    <BigFigure {...props} renderValue={(v) => {
      if(v === undefined) {
        return null
      }
      return <span className={`Component_check ${v?.toString() === props.okValue ? 'ok' : 'ko'}`}></span>
    }} />
  )
}

export const BigFigure = (props: {
  title?: string
  value?: string
  extValue?: {
    value?: string
    alert?: 'Warning' | 'Error'
  }
  renderValue?: (v?: string) => ReactNode
  legend?: string | ReactNode
  indicator?: string
  small?: boolean
  alert?: any
  on?: string | ReactNode
}) => {
  return (
    <div className="Component_column">
      <div className="Component_title">{props.title}</div>
      <div
        className={!props.small ? 'Component_bigfigure' : 'Component_figure'}
      >
        {props.renderValue ? props.renderValue(props.extValue ? props.extValue?.value : props.value): (props.extValue ? props.extValue?.value : props.value)}
        {props.on ? <span className="Component_on"> / {props.on}</span> : undefined}
        {props.indicator !== undefined && (
          <div className="Component_indicator">{props.indicator}</div>
        )}
        {props.extValue?.alert && (
        <div className="Component_alert">
          <Warning
            fill={props.extValue?.alert === 'Warning' ? 'orange' : 'red'}
            width="24"
          />
        </div>
      )}
      </div>
      <div className="Component_legend">{props.legend}</div>
    </div>
  )
}

export const Card = (
  props: PropsWithChildren<{
    title?: string
    className?: string
    href?: string
  }>
) => {
  return (
    <a target="_blank" href={props.href} className={`Card ${props.className}`} rel="noreferrer">
      <div className="Card_title">{props.title}</div>
      <div className="Card_content">{props.children}</div>
      {/*<div onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        console.log('ADD GR')
      }} className="Card_addGraph">
        + Ajouter au graphique
    </div>*/}
    </a>
  )
}
