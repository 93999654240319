import React from 'react'

export const Banner = (props: {
  title: string,
  statusColor?: string
}) => {
  return (
    <div className="Banner" style={{backgroundColor: props.statusColor}}>
      {props.title}
    </div>
  )
}
