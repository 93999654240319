import React, { PropsWithChildren } from 'react'
import { useState } from 'react'

export const Block = (props: PropsWithChildren<{
  title: string,
  status?: string,
  statusColor?: string
}>) => {
  const [isOpened, setIsOpened] = useState(false)
  return (
    <div className={`status_block${isOpened ? ' OPEN' : ''}`}>
      <h2 className={isOpened ? 'OPEN' : undefined} onClick={() => setIsOpened(!isOpened)}>{props.title}</h2>
      <Status status={props.status} color={props.statusColor} style={{
        position: 'absolute',
        top: 20,
        right: 20
      }} />
      <div className="status_block_content">
        {props.children}
      </div>
    </div>
  )
}


export const Status = (props: {
  status?: string,
  color?: string,
  style?: React.CSSProperties
}) => {
  return (
    <div style={{
      ...props.style,
      color: props.color || 'green'
    }} className="status_status">
      {props.status}
    </div>
  )
}