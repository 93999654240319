import dayjs from 'dayjs'
import React from 'react'
import { Card, Check } from '../../component/BigFigure'
import { Block } from '../../component/Block'
import { useMetrics } from '../../hook/useMetrics'

const dateToString = (value?: string) => {
  if (!value) {
    return ''
  }
  return dayjs(value).format('DD/MM/YY [à] HH:mm')
}

export const CampinTemplateConstruire = () => {
  const [getMetric, status, statusColor] = useMetrics()

  return (
    <Block title="Construire" status={status} statusColor={statusColor}>
      <div className="Component_row">
        <Card href={getMetric('gitlab.commit.develop.url').value}>
          <div className="Component_column">
            <div className="Component_title">Dernier commit</div>
            <div className="Component_texte">
              {getMetric('gitlab.commit.develop.message').value}
            </div>
            <div className="Component_legend">
              {getMetric('gitlab.commit.develop.author').value}
            </div>
            <div className="Component_date">
              {dateToString(getMetric('gitlab.commit.develop.date').value)}
            </div>
          </div>
        </Card>
        <Card href={getMetric('gitlab.job.build.url').value}>
          <Check
            title="job build"
            extValue={getMetric('gitlab.job.build.status')}
            small
            legend={dateToString(getMetric('gitlab.job.build.date').value)}
            okValue="success"
          />
        </Card>
      </div>
    </Block>
  )
}
