import React, { ReactElement } from 'react'
import { useEffect } from 'react'
import './Root.css'
import { AllMetrics } from '../common/Campin'
import { useState } from 'react'
import { Banner } from './component/Banner'
import { CampinTemplate } from './template/campin.template'
import { CampinTemplatePublic } from './template/campinpublic.template'
import dayjs from 'dayjs'

type TemplateNames = 'campin' | 'campinpublic'

const Templates: Record<TemplateNames, (getMetric: (name: AllMetrics) => string) => ReactElement> = {
  campin: CampinTemplate as any,
  campinpublic: CampinTemplatePublic as any
}

export const MetricsContext = React.createContext<{
  getMetric: (name: AllMetrics) => string | undefined,
  getAlert: (name: AllMetrics) => 'Warning' | 'Error' | undefined,
}>({
  getMetric: () => { return undefined },
  getAlert: () => { return undefined }
});

const dateToString = (value?: string) => {
  if (!value) {
    return ''
  }
  return dayjs(value).format('DD/MM/YY [à] HH:mm')
}


export const Root = () => {
  const [metrics, setMetrics] = useState<
    | {
        date: string
        logo: string
        template: TemplateNames
        alerts:  Array<{
          id: string
          level: 'Warning' | 'Error'
        }>
        metrics: Array<{
          id: string
          value: any
        }>
      }
    | undefined
  >(undefined)

  const getMetric = (name: AllMetrics) => {
    if (metrics && metrics.metrics) {
      return metrics.metrics.find((m) => m.id === name)?.value
    }
  }

  const getAlert = (name: AllMetrics) => {
    if (metrics && metrics.alerts) {
      return metrics.alerts.find((m) => m.id === name)?.level
    }
  }

  useEffect(() => {
    ;(async () => {
      let name = 'metrics'
      if(window.location.hash) {
        name = window.location.hash.replace('#', '')
      }
      const response = await fetch(`./${name}.json`)
      setMetrics(await response.json())
    })()
  }, [])

  const errors = metrics?.alerts.filter(a => a.level === 'Error').length
  const warnings = metrics?.alerts.filter(a => a.level === 'Warning').length

  let status = 'Opérationnel'
  let statusColor = 'green'
  if(errors) {
    status = `${errors} incidents majeur en cours`
    statusColor = 'red'
  } else if(warnings) {
    status = `${warnings} incidents mineurs en cours`
    statusColor = 'orange'
  }


  return (
    <MetricsContext.Provider value={{
      getMetric,
      getAlert
    }}>
    <div id="status_root">
      <div className="status_header">
        <img src={`./${metrics?.logo}.png`} height="50" alt="" />
        <div className="status_date">{metrics ? `Mis à jour le ${dateToString(metrics?.date)}` : ''}</div>
      </div>
      <Banner title={status} statusColor={statusColor} />
      {(metrics && metrics.template) && Templates[metrics.template](getMetric)}
    </div>
    </MetricsContext.Provider>
  )
}
