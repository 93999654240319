import React from 'react'
import { Check, Card } from '../../component/BigFigure'
import { Block } from '../../component/Block'
import { useMetrics } from '../../hook/useMetrics'

export const API = () => {
  const [getMetric, status, statusColor] = useMetrics()
  return (
    <Block title="API" status={status} statusColor={statusColor}>
      <div className="Component_row">
        <Card>
          <Check
            title="HTTP"
            extValue={getMetric('tiers.api')}
            small
            okValue="success"
          />
        </Card>
      </div>
    </Block>
  )
}

export const Backoffice = () => {
  const [getMetric, status, statusColor] = useMetrics()
  return (
    <Block title="Backoffice" status={status} statusColor={statusColor}>
      <div className="Component_row">
        <Card>
          <Check
            title="HTTP"
            extValue={getMetric('tiers.backoffice')}
            small
            okValue="success"
          />
        </Card>
      </div>
    </Block>
  )
}

export const Mobile = () => {
  const [getMetric, status, statusColor] = useMetrics()
  return (
    <Block title="Mobile" status={status} statusColor={statusColor}>
      <div className="Component_row">
        <Card>
          <Check
            title="Google Play"
            extValue={getMetric('tiers.mobile.android')}
            small
            okValue="success"
          />
        </Card>
        <Card>
          <Check
            title="Apple Store"
            extValue={getMetric('tiers.mobile.ios')}
            small
            okValue="success"
          />
        </Card>
      </div>
    </Block>
  )
}

export const Tiers = () => {
  const [getMetric, status, statusColor] = useMetrics()
  return (
    <Block title="Services tiers" status={status} statusColor={statusColor}>
      <div className="Component_row">
        <Card>
          <Check
            title="DNS"
            extValue={getMetric(`newrelic.synthetic.prod.failed`)}
            small
            okValue="0"
          />
        </Card>
        <Card>
          <Check
            title="Email"
            extValue={getMetric('tiers.mail')}
            small
            okValue="success"
          />
        </Card>
      </div>
    </Block>
  )
}
