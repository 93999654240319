import React from 'react'
import { envs } from '../../../common/Campin'
import { BigFigure, Card, Check } from '../../component/BigFigure'
import { Block } from '../../component/Block'
import { useMetrics } from '../../hook/useMetrics'

const PAR_ENV = {
  test: {
    hosts: ['ANIKOP_REVERSE_PROXY', 'ANIKOP_TEST']
  },
  demo: {
    hosts: ['ANIKOP_REVERSE_PROXY', 'CAMPIN_DEMO', 'CAMPIN_DB']
  },
  prod: {
    hosts: ['ANIKOP_REVERSE_PROXY', 'CAMPIN_PROD', 'CAMPIN_DB']
  }
}

export const CampinTemplateOperer = (props: { env: envs; boUrl: string }) => {
  const [getMetric, status, statusColor] = useMetrics()

  const envConfig = PAR_ENV[props.env]

  return (
    <Block
      title={`Opérer (env. ${props.env})`}
      status={status}
      statusColor={statusColor}
    >
      <h3>Données Camp'In</h3>
      <Card href={props.boUrl}>
        <BigFigure
          value={getMetric(`campin.${props.env}.camping_actif`).value}
          on={getMetric(`campin.${props.env}.camping_total`).value}
          small
          legend={
            <>
              camping
              <br />
              (actifs / total)
            </>
          }
        />
        <BigFigure
          value={getMetric(`campin.${props.env}.campeur_actif`).value}
          on={getMetric(`campin.${props.env}.campeur_total`).value}
          small
          legend={
            <>
              campeurs
              <br />
              (actifs / total)
            </>
          }
        />
        <BigFigure
          value={getMetric(`campin.${props.env}.sejour_en_cours_total`).value}
          small
          legend="sejours en cours"
        />
        <BigFigure
          value={
            getMetric(`campin.${props.env}.sejour_en_cours_invitation`).value
          }
          on={getMetric(`campin.${props.env}.invitation_total`).value}
          small
          legend={
            <>
              invitations
              <br />
              (sejour en cours / total)
            </>
          }
        />
        <BigFigure
          value={getMetric(`campin.${props.env}.campeur_non_valide`).value}
          small
          legend="campeur non validé"
        />
        <BigFigure
          value={getMetric(`campin.${props.env}.campeur_verrouille`).value}
          small
          legend="campeur vérouillé"
        />
      </Card>
      <h3>Serveurs</h3>
      {envConfig.hosts.map((h) => {
        return (
          <Card title={h} key={h} href="https://one.eu.newrelic.com/">
            <BigFigure
              extValue={getMetric(`newrelic.host.${h}.cpu` as any)}
              small
              renderValue={(v) => {
                if (v) {
                  return Math.round(parseFloat(v) * 100) / 100 + '%'
                }
              }}
              legend="CPU"
            />
            <BigFigure
              extValue={getMetric(`newrelic.host.${h}.memory` as any)}
              small
              renderValue={(v) => {
                if (v) {
                  return Math.round(parseFloat(v) * 100) / 100 + '%'
                }
              }}
              legend="RAM"
            />
            <BigFigure
              extValue={getMetric(`newrelic.host.${h}.storage` as any)}
              small
              renderValue={(v) => {
                if (v) {
                  return Math.round(parseFloat(v) * 100) / 100 + '%'
                }
              }}
              legend="HDD"
            />
          </Card>
        )
      })}
      <h3>Applications</h3>
      <div className="Component_row">
        {props.env === 'prod' && (
          <>
            <Card href="https://bo.campin.fr/api/">
              <Check
                title="Check HTTP API"
                extValue={getMetric('tiers.api')}
                small
                okValue="success"
              />
            </Card>
            <Card href="https://bo.campin.fr/">
              <Check
                title="Check HTTP Backoffice"
                extValue={getMetric('tiers.backoffice')}
                small
                okValue="success"
              />
            </Card>
          </>
        )}

        <Card title="Campin Backend" href="https://one.eu.newrelic.com/">
          <BigFigure
            extValue={getMetric(
              `newrelic.apm.campin_backend${
                props.env === 'prod' ? '' : `_${props.env}`
              }.request_per_minute` as any
            )}
            small
            renderValue={(v) => {
              if (v !== undefined) {
                return Math.round((parseFloat(v) / 60) * 100) / 100
              }
            }}
            legend="requêtes par seconde"
          />
          <BigFigure
            extValue={getMetric(
              `newrelic.apm.campin_backend${
                props.env === 'prod' ? '' : `_${props.env}`
              }.errors` as any
            )}
            small
            renderValue={(v) => {
              if (v !== undefined) {
                return Math.round(parseFloat(v) * 100) / 100
              }
            }}
            legend="erreurs"
          />
        </Card>
      </div>
      <div className="Component_row">
        <Card href="https://one.eu.newrelic.com/">
          <BigFigure
            title="Nginx"
            extValue={getMetric('newrelic.nginx.rps')}
            small
            renderValue={(v) => {
              if (v) {
                return Math.round(parseFloat(v) * 100) / 100
              }
            }}
            legend="requêtes par seconde"
          />
        </Card>
        {props.env !== 'test' && (
          <Card title="MariaDB" href="https://one.eu.newrelic.com/">
            <BigFigure
              extValue={getMetric('newrelic.mysql.queries_per_second')}
              small
              renderValue={(v) => {
                if (v) {
                  return Math.round(parseFloat(v) * 100) / 100
                }
              }}
              legend="requêtes par seconde"
            />
            <BigFigure
              extValue={getMetric('newrelic.mysql.max_connections')}
              small
              legend="connections (max)"
            />
            <BigFigure
              extValue={getMetric('newrelic.mysql.slowqueries_per_second')}
              small
              legend="requêtes lentes par seconde"
            />
          </Card>
        )}
      </div>
      <h3>Services tiers</h3>
      <div className="Component_row">
        <Card href="https://one.eu.newrelic.com/">
          <Check
            title="DNS"
            extValue={getMetric(`newrelic.synthetic.${props.env}.failed`)}
            small
            okValue="0"
          />
        </Card>
        <Card>
          <Check
            title="Email"
            extValue={getMetric(
              props.env === 'test' ? 'tiers.mail_test' : 'tiers.mail'
            )}
            small
            okValue="success"
          />
        </Card>
        {props.env === 'prod' && (
          <>
            <Card href="https://play.google.com/store/apps/details?id=com.campinapp.master">
              <Check
                title="Google Play"
                extValue={getMetric('tiers.mobile.android')}
                small
                okValue="success"
              />
            </Card>
            <Card href="https://apps.apple.com/fr/app/campin/id1568664736">
              <Check
                title="Apple Store"
                extValue={getMetric('tiers.mobile.ios')}
                small
                okValue="success"
              />
            </Card>
          </>
        )}
      </div>
    </Block>
  )
}
