import React from 'react'
import { API, Backoffice, Mobile, Tiers } from './campin/public'

export const CampinTemplatePublic = () => {
  return (
    <div>
      <API />
      <Backoffice />
      <Mobile />
      <Tiers />      
    </div>
  )
}
