import { useContext, useState } from "react"
import { AllMetrics } from "../../common/Campin"
import { MetricsContext } from "../Root"


export function useMetrics () : [(name: AllMetrics) => { value: string | undefined, alert?: 'Warning' | 'Error' }, string, string, { id: string, level: 'Warning' | 'Error' }[]] {
  const metricCtx = useContext(MetricsContext)
  const [alerts, setAlerts] = useState<{ id: string, level: 'Warning' | 'Error' }[]>([])
  const getMetric = (name: AllMetrics) => {
    const alert = metricCtx.getAlert(name)
    if (alert && !alerts.find(a => a.id === name)) {
      setAlerts(alerts.concat([{
        id: name,
        level: alert
      }]))
    } else if(!alert && alerts.find(a => a.id === name)) {
      setAlerts(alerts.filter(n => n.id === name))
    }
    return {
      value: metricCtx.getMetric(name),
      alert
    }
  }

  const errors = alerts.filter(a => a.level === 'Error').length
  const warnings = alerts.filter(a => a.level === 'Warning').length

  let status = 'Opérationnel'
  let statusColor = 'green'
  if(errors) {
    status = `${errors} incidents majeur en cours`
    statusColor = 'red'
  } else if(warnings) {
    status = `${warnings} incidents mineurs en cours`
    statusColor = 'orange'
  }

  return [
    getMetric,
    status,
    statusColor,
    alerts
  ]

}