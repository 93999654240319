import dayjs from 'dayjs'
import React from 'react'
import { apps } from '../../../common/Campin'
import { BigFigure, Card, Check } from '../../component/BigFigure'
import { Block } from '../../component/Block'
import { useMetrics } from '../../hook/useMetrics'

const convertMinutesToDaysHours = (minutes: number) => {
  const days = Math.floor(minutes / (60 * 8))
  const hours = Math.floor((minutes - days * 8 * 60) / 60)
  const minu = minutes - days * 8 * 60 - hours * 60
  return `${days ? days + 'j ' : ''}${hours}h ${minu}m`
}

const dateToString = (value?: string) => {
  if (!value) {
    return ''
  }
  return dayjs(value).format('DD/MM/YY [à] HH:mm')
}

export const CampinTemplateTester = () => {
  const [getMetric, status, statusColor] = useMetrics()

  return (
    <Block title="Tester" status={status} statusColor={statusColor}>
      <div className="Component_row">
        <Card href={getMetric('gitlab.job.lint.url').value}>
          <Check
            title="job lint"
            extValue={getMetric('gitlab.job.lint.status')}
            small
            legend={dateToString(getMetric('gitlab.job.lint.date').value)}
            okValue="success"
          />
        </Card>
      </div>
      {['backend', 'frontend', 'agent-scheduler', 'mobile'].map((app) => {
        return (
          <React.Fragment key={app}>
            <h3>{app}</h3>
            <div className="Component_row">
              <Card href={getMetric(`gitlab.job.test_${app}.url` as any).value}>
                <Check
                  title="job test"
                  extValue={getMetric(`gitlab.job.test_${app}.status` as any)}
                  small
                  legend={dateToString(
                    getMetric(`gitlab.job.test_${app}.date` as any).value
                  )}
                  okValue="success"
                />
              </Card>
              <Card
                title="indicateurs qualité"
                href={`https://sonarcloud.io/dashboard?id=anikopcampin-${app}`}
              >
                <BigFigure
                  extValue={getMetric(`sonarqube.${app as apps}.bugs`)}
                  small
                  legend="Bugs"
                />
                <BigFigure
                  extValue={getMetric(
                    `sonarqube.${app as apps}.vulnerabilities`
                  )}
                  small
                  legend="Vulnérabilités"
                />
                <BigFigure
                  extValue={getMetric(`sonarqube.${app as apps}.sqale_index`)}
                  renderValue={(v) => {
                    return convertMinutesToDaysHours(parseInt(v || '0'))
                  }}
                  small
                  legend="Dette technique"
                />
                <BigFigure
                  extValue={getMetric(`sonarqube.${app as apps}.coverage`)}
                  renderValue={(v) => {
                    return v + '%'
                  }}
                  small
                  legend="Couverture de code"
                />
                <BigFigure
                  extValue={getMetric(
                    `sonarqube.${app as apps}.duplicated_lines_density`
                  )}
                  renderValue={(v) => {
                    return v + '%'
                  }}
                  small
                  legend="Duplication"
                />
                <BigFigure
                  extValue={getMetric(`sonarqube.${app as apps}.ncloc`)}
                  renderValue={(v) => {
                    return Math.round(parseInt(v || '0') / 1000) + 'k'
                  }}
                  small
                  legend="Nb lignes de code"
                />
              </Card>
            </div>
          </React.Fragment>
        )
      })}
    </Block>
  )
}
